import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/theme/src/layouts/page.js";
import { TwitterTweetEmbed } from 'react-twitter-embed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uses"
    }}>{`Uses`}</h1>
    <hr></hr>
    <p>{`Sometimes I wonder what others’ development environment looks like. What computer are they using? What OS do they like? What editor fits their needs? Well, here’s a comprehensive list of my picks.`}</p>
    <br />
    <h2 {...{
      "id": "computer--keyboards"
    }}>{`Computer + Keyboards`}</h2>
    <ul>
      <li parentName="ul">{`2017 13” MacBook Pro`}</li>
      <li parentName="ul">{`my recently-built Planck with 78g Zealio Purples`}<TwitterTweetEmbed tweetId={'1145384306531024897'} mdxType="TwitterTweetEmbed" /></li>
      <li parentName="ul">{`pok3r with Cherry MX Clears`}</li>
      <li parentName="ul">{`KBParadise v60 with Matias Quiet Pro’s`}</li>
      <li parentName="ul">{`IBM Model M`}</li>
    </ul>
    <h2 {...{
      "id": "gear"
    }}>{`Gear`}</h2>
    <ul>
      <li parentName="ul">{`Phone: Pixel 3`}</li>
      <li parentName="ul">{`Backpack: `}<a parentName="li" {...{
          "href": "https://i.pinimg.com/originals/95/4e/e6/954ee6c0068a4e77ddfa79cdb74dc19c.jpg"
        }}>{`2014 Timbuk2 Q`}</a></li>
      <li parentName="ul">{`Headphones: `}<a parentName="li" {...{
          "href": "https://en-us.sennheiser.com/monitoring-headphone-studio-professional-audio-hd-380-pro"
        }}>{`Sennheiser 380 Pro`}</a></li>
      <li parentName="ul">{`Monitor: `}<a parentName="li" {...{
          "href": "https://www.lg.com/us/monitors/lg-27UK600-W-4k-uhd-led-monitor"
        }}>{`LG 27” 4k - UK600-W`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.com/Rain-Design-mStand-Laptop-Patented/dp/B000OOYECC/ref=sr_1_1?crid=1ZYJ2TAG619GK&keywords=rain+mstand&qid=1569550521&s=electronics&sprefix=rain+m%2Celectronics%2C177&sr=1-1"
        }}>{`Rain mStand`}</a></li>
      <li parentName="ul">{`Zebra F-301 0.5mm ballpoint pen`}</li>
      <li parentName="ul">{`Magic Trackpad 2`}</li>
    </ul>
    <h2 {...{
      "id": "plants"
    }}>{`Plants`}</h2>
    <ul>
      <li parentName="ul">{`Pothos Ivy`}<TwitterTweetEmbed tweetId={'1170454511321845760'} mdxType="TwitterTweetEmbed" /></li>
      <li parentName="ul">{`Green Onions`}<TwitterTweetEmbed tweetId={'1174809707502026752'} mdxType="TwitterTweetEmbed" /></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      